//PRODUCTS LINKS
const PREVIEW_PROJECT_LINK = process.env.GATSBY_PREVIEW_PROJECT_LINK;
const PUBLISH_PROJECT_LINK = process.env.GATSBY_PUBLISH_PROJECT_LINK;
const IS_PUBLISHED = process.env.GATSBY_IS_PUBLISHED == "true" ? true : false;

const PROJECT_LINK = IS_PUBLISHED ? PUBLISH_PROJECT_LINK : PREVIEW_PROJECT_LINK;

const GATSBY_DEFAULT_LANGUAGE = process.env.GATSBY_DEFAULT_LANGUAGE;
const GATSBY_PRODUCT_PER_PAGE = process.env.GATSBY_PRODUCT_PER_PAGE;
const GATSBY_SHOP_CATEGORY_CID = process.env.GATSBY_SHOP_CATEGORY_CID;
const PATH_PREFIX = process.env.GATSBY_PATH_PREFIX;

const apiUrl = product => `${product}?tpt=json_${GATSBY_DEFAULT_LANGUAGE}`;
const productUrl = id =>
  `${PROJECT_LINK}/uservices/1.0.2/product/${process.env.GATSBY_VID}/iid/${id}/lang/${GATSBY_DEFAULT_LANGUAGE}/`;
const supplierUrl = id =>
  `${PROJECT_LINK}/uservices/1.0.2/suppliers/${process.env.GATSBY_VID}/iid/${id}/lang/${GATSBY_DEFAULT_LANGUAGE}/`;
const priceInventoryUrl = id =>
  `${PROJECT_LINK}/uservices/1.0.2/priceinventory/${process.env.GATSBY_VID}/iid/${id}/lang/${GATSBY_DEFAULT_LANGUAGE}/`;

//CATEGORIES LINKS
const menuUrl = `${PROJECT_LINK}/uservices/1.0.2/menu/${process.env.GATSBY_VID}/category/menu1/lang/${GATSBY_DEFAULT_LANGUAGE}/`;
const categoryUrl = id =>
  `${PROJECT_LINK}/uservices/1.0.2/category-page/${process.env.GATSBY_VID}/cid/${id}/lang/${GATSBY_DEFAULT_LANGUAGE}/page/1/showperpage/${GATSBY_PRODUCT_PER_PAGE}/`;
const pagingUrl = (id, page) =>
  `${PROJECT_LINK}/uservices/1.0.2/category-paging/${process.env.GATSBY_VID}/cid/${id}/lang/${GATSBY_DEFAULT_LANGUAGE}/page/${page}/showperpage/${GATSBY_PRODUCT_PER_PAGE}/`;

//STORES LINKS
const categoryUrlSeller = param =>
  `${PROJECT_LINK}/uservices/1.0.2/category-page/${process.env.GATSBY_VID}/cid/${GATSBY_SHOP_CATEGORY_CID}/lang/${GATSBY_DEFAULT_LANGUAGE}/page/1/showperpage/${GATSBY_PRODUCT_PER_PAGE}/&Sellers=${param}`;
const pagingUrlSeller = (param, page) =>
  `${PROJECT_LINK}/uservices/1.0.2/category-paging/${process.env.GATSBY_VID}/cid/${GATSBY_SHOP_CATEGORY_CID}/lang/${GATSBY_DEFAULT_LANGUAGE}/page/${page}/showperpage/${GATSBY_PRODUCT_PER_PAGE}/&Sellers=${param}`;

//PREVIEW PRODUCTS LINKS
const previewApiUrl = product =>
  `${PROJECT_LINK}/${product}?tpt=json_${GATSBY_DEFAULT_LANGUAGE}`;
const apiUrlNew = productCode =>
  `${PROJECT_LINK}/storeitem.html?vid=${process.env.GATSBY_VID}&iid=${productCode}&tpt=json_${GATSBY_DEFAULT_LANGUAGE}`;

module.exports = {
  apiUrl,
  productUrl,
  supplierUrl,
  priceInventoryUrl,
  menuUrl,
  categoryUrl,
  pagingUrl,
  categoryUrlSeller,
  pagingUrlSeller,
  previewApiUrl,
  apiUrlNew,
  GATSBY_DEFAULT_LANGUAGE,
  PATH_PREFIX
};
