import React, { useEffect } from "react";
import { setHTMLElementFixedPosition } from "../../../../../../functions/Utilities";
import classes from "./Modal.module.css";

export default function Modal({ children }) {
  useEffect(() => {
    setHTMLElementFixedPosition(true);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>{children}</div>
    </div>
  );
}
