import React, { useContext, useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { PREVIEW } from "../../../../project-config.js";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import {
  fetchCategoryFromRender,
  changeCategoryName,
  backToCategory
} from "../../../../redux/actions/categoryActions";

import { I18nContext } from "../../../../i18n/index";

const Breadcrumb = ({ back }) => {
  const dispatch = useDispatch();
  const { langCode, translate } = useContext(I18nContext);
  const [breadcrumb, setBreadcrumb] = useState([]);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const categoryParentsNameState = useSelector(
    state => state.categoryReducer.parents,
    shallowEqual
  );

  const breadcrumbState = useSelector(
    state => state.mainReducer.breadcrumb,
    shallowEqual
  );

  useEffect(() => {
    if (breadcrumbState && breadcrumbState.length > 1) {
      setBreadcrumb(
        breadcrumbState.filter(
          bread => bread.name !== "Home" && bread.name !== "Shop"
        )
      );
    }
  }, [breadcrumbState]);

  const handleBreadCrumbClicked = (cid, cat, parents) => {
    let category = navCatsState;
    for (let parent of parents) {
      category = category.childs.filter(c => {
        if (c.cid === parent[1]) {
          /*parent's cid equals cid */
          return true;
        }
      })[0];
    }

    dispatch(changeCategoryName(cat, parents));
    dispatch(fetchCategoryFromRender(cid, cat, parents, "", category));
  };

  return (
    <div
      className="sub-nav-bread"
      style={{
        padding: "10px 0",
        width: "90%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        maxWidth: "1440px"
      }}
    >
      <ul className="breadcrumb">
        <li typeof="v:Breadcrumb" style={{ color: "#a5a5a5" }}>
          <Link
            className="text-link"
            to={langCode === "en" ? `/` : `/${langCode}`}
            property="v:title"
            rel="v:url"
          >
            {`${translate("Home")} > `}
          </Link>
          {breadcrumb.map((bread, index) => {
            let url = bread.url.replace("shop");
            if (back) {
              return (
                <React.Fragment key={index}>
                  <span
                    onClick={() => {
                      dispatch(backToCategory());
                      navigate(-1);
                    }}
                    style={{ cursor: "pointer", fontSize: "0.8125em" }}
                    className={
                      index == categoryParentsNameState.length - 1
                        ? "final_look last_breadcrumb final-bc"
                        : "home_look"
                    }
                    dangerouslySetInnerHTML={{ __html: bread.name }}
                  ></span>
                  {index == breadcrumb.length - 1 ? "" : " > "}
                </React.Fragment>
              );
            } else {
              return (
                <Link key={index} className="text-link" to={url}>
                  <span
                    className={
                      index == categoryParentsNameState.length - 1
                        ? "final_look last_breadcrumb final-bc"
                        : "home_look"
                    }
                    dangerouslySetInnerHTML={{ __html: bread.name }}
                  ></span>
                  {index == categoryParentsNameState.length - 1 ? "" : " > "}
                </Link>
              );
            }
          })}
        </li>
      </ul>
      {/*   {back && (
        <button
          className="backBtn"
          onClick={() => {
            dispatch(backToCategory());
            navigate(-1);
          }}
        >
          <span class="material-icons">keyboard_backspace</span>
        </button>
      )} */}
    </div>
  );
};

export default Breadcrumb;
