import React, { useState, useEffect } from "react";
import { LINK_DISTRIBUTION } from "../../../../../project-config";
import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";
import { GET_LOGO_CLIPARTS } from "../../../../../redux/links";

import classes from "./ExtendedLogoSection.module.css";
import LogoUpload from "./LogoUpload";
import Select from "./Select";

export default function ExtendedLogoSection({
  questions,
  dependedFields,
  setInputsState,
  logoMode,
  progressState,
  dispatch,
  validationErrors
}) {
  const [selectedLogoCategory, setSelectedLogoCategory] = useState({
    value: "all",
    text: "All"
  });

  const [filteredCliparts, setFilteredCliparts] = useState(null);

  const [clipArts, setClipArts] = useState({ clipArts: [], categories: [] });

  useEffect(() => {
    if (logoMode === "pickClipart") {
      fetch(GET_LOGO_CLIPARTS())
        .then(res => res.json())
        .then(json => {
          if (json && json.__Result) {
            let clipArts = json.__Result.reduce((a, c) => {
              let [category, id] = c.split("-");
              a.push({
                id,
                category,
                url: `${LINK_DISTRIBUTION}/store/20201005259/assets/logos/clipart/${c}`
              });
              return a;
            }, []);

            let categories = [
              ...new Set(
                clipArts.reduce((a, c) => {
                  a.push(c.category);
                  return a;
                }, [])
              )
            ];
            categories = categories.map(category => ({
              value: category,
              text: category
            }));
            if (categories.length > 0)
              categories.unshift({
                value: "all",
                text: "All"
              });

            setClipArts({ clipArts, categories });
          }
        })
        .catch(err => {
          console.info("error fetching the cliparts", err);
          setClipArts({ clipArts: null, categories: [] });
        });
    }
  }, [logoMode]);

  useEffect(() => {
    if (selectedLogoCategory.value !== "all" && clipArts && clipArts.clipArts) {
      setFilteredCliparts(
        clipArts.clipArts.filter(
          art => art.category === selectedLogoCategory.value
        )
      );
    } else setFilteredCliparts(null);
  }, [selectedLogoCategory]);

  const renderSectionContent = () => {
    console.info("logo Upload1", logoMode);

    if (logoMode === "pickClipart") {
      let fieldDependsOnLogoModeAndPickClipart = dependedFields.find(
        field =>
          field.dependedOn === "logoMode" &&
          field.logoModeRequiredState.includes("pickClipart")
      );

      console.info("foundQ", fieldDependsOnLogoModeAndPickClipart);
      const handleBoxClicked = (e, box) => {
        console.info("box clicked", box, progressState);

        if (fieldDependsOnLogoModeAndPickClipart) {
          let firstDetailIdOrQuesID =
            (fieldDependsOnLogoModeAndPickClipart.quesDetails &&
              fieldDependsOnLogoModeAndPickClipart.quesDetails[0] &&
              fieldDependsOnLogoModeAndPickClipart.quesDetails[0].detailID) ||
            fieldDependsOnLogoModeAndPickClipart.quesID;
          setInputsState({
            [firstDetailIdOrQuesID]: box.id
          });
        }

        dispatch(
          setPersonalizedQuestionsProgressAction({
            logo: { ...progressState.logo, selectedClipart: box }
          })
        );
      };
      return (
        <React.Fragment>
          <div className={classes.selectSection}>
            <Select
              setState={setSelectedLogoCategory}
              label="Logos"
              field="artCategory"
              options={clipArts.categories}
            />
            <div className={classes.selectedArtWrapper}>
              <label>Selected</label>
              <div
                style={
                  progressState.logo.selectedClipart
                    ? {
                        backgroundImage: `url(${progressState.logo.selectedClipart.url})`
                      }
                    : { background: "unset", backgroundColor: "#ddd" }
                }
                className={classes.selectedArtBox}
              ></div>
            </div>
          </div>
          <div className={classes.clipartsContainer}>
            <div className={`${classes.clipartsWrapper} scroll-bar-thin-style`}>
              {(filteredCliparts || clipArts.clipArts).map(box => (
                <div className={classes.clipartBoxWrapper}>
                  <div
                    style={{ backgroundImage: `url(${box.url})` }}
                    className={`${classes.clipartBox}${
                      box.id ===
                      (progressState.logo.selectedClipart &&
                        progressState.logo.selectedClipart.id)
                        ? " " + classes.active
                        : ""
                    }`}
                    onClick={e => handleBoxClicked(e, box)}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    } else if (logoMode === "uploadLogo") {
      console.log("question33", questions.restOfTheFields);
      const logoUploadQuestion = questions.restOfTheFields.find(
        q => q.quesDesc.toLowerCase() === "logo upload"
      );

      console.info("logo Upload", logoUploadQuestion);

      const firstDetail =
        logoUploadQuestion &&
        logoUploadQuestion.quesDetails &&
        logoUploadQuestion.quesDetails[0];

      if (firstDetail) {
        return (
          <LogoUpload
            setInputsState={setInputsState}
            logoInputField={firstDetail}
            dispatch={dispatch}
            progressState={progressState}
            validationErrors={validationErrors}
          />
        );
      }
      return null;
    } else return null;
  };

  return <div className={classes.wrapper}>{renderSectionContent()}</div>;
}
