import React, { useCallback, useEffect } from "react";
import UTIF from "utif";

import { useDropzone } from "react-dropzone";
import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";
import handleUploadLogo from "../utils/handleUploadLogo";

import classes from "./LogoUpload.module.css";
import { BASE_PATH } from "../../../../../project-config";

export default function LogoUpload({
  setInputsState,
  logoInputField,
  progressState,
  dispatch,
  validationErrors
}) {
  const uploadedLogo = progressState.logo.uploadedLogo;

  const handleTiffImageUrlToArrayBuffer = url => {
    const xhr = new XMLHttpRequest();

    xhr.open("GET", url);
    xhr.responseType = "arraybuffer";
    xhr.onload = imgLoaded;
    xhr.send();

    function imgLoaded(e) {
      let ifds = UTIF.decode(e.target.response);
      UTIF.decodeImage(e.target.response, ifds[0]);
      let rgba = UTIF.toRGBA8(ifds[0]); // Uint8Array with RGBA pixels
      dispatch(
        setPersonalizedQuestionsProgressAction({
          logo: {
            ...progressState.logo,
            uploadedLogo: null,
            tiff: {
              width: ifds[0].width,
              height: ifds[0].height,
              data: rgba
            }
          }
        })
      );

      // console.log("BUFFER", ifds[0].width, ifds[0].height, ifds[0], rgba);
    }

    // binaryStr = bufferArray;
  };
  console.info("validationErrors3", validationErrors);

  const requiredValidationFailed = Object.keys(validationErrors).some(
    key => key === "logo-upload-wrapper"
  );
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    let newFile = acceptedFiles.pop();
    const reader = new FileReader();
    const callBack = (json, tiffImage) => {
      if (json && json.fileName && json.longDir) {
        setInputsState({
          [logoInputField.detailID]: [json.fileName, json.longDir]
        });
        console.log("logo upload", json, tiffImage);
        if (tiffImage) {
          handleTiffImageUrlToArrayBuffer(`${BASE_PATH}${json.longDir}`);
        } else {
          dispatch(
            setPersonalizedQuestionsProgressAction({
              logo: {
                ...progressState.logo,
                uploadedLogo: `${BASE_PATH}${json.longDir}`,
                tiff: null
              }
            })
          );
        }
      } else {
        dispatch(
          setPersonalizedQuestionsProgressAction({
            logo: {
              ...progressState.logo,
              uploadedLogo: null,
              tiff: null
            }
          })
        );
      }
    };

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;

      let tiffImage = false;

      if (binaryStr.includes("image/tif")) tiffImage = true;

      console.info("binaryStr", binaryStr);

      handleUploadLogo(logoInputField.detailID, binaryStr, callBack, tiffImage);

      dispatch(
        setPersonalizedQuestionsProgressAction({
          logo: { ...progressState.logo, uploadedLogo: binaryStr }
        })
      );
    };
    reader.readAsDataURL(newFile);
  }, []);

  useEffect(() => {
    return () => {
      setInputsState({
        [logoInputField.detailID]: null
      });
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className={classes.wrapper}>
      {/*  <p className={classes.contact}>
        Need Help? <a href="#">Chat</a> or Call:{" "}
        <a href="tel:+1800-555-5555">1-800-555-55-55</a>
      </p> */}
      <div
        className={`${classes.dropZone}${
          uploadedLogo ? " " + classes.haveLogo : ""
        }`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : !uploadedLogo ? (
          <p>Drag and drop or Click here to upload a logo</p>
        ) : (
          <React.Fragment>
            <img
              className={classes.logo}
              src={uploadedLogo}
              alt="uploaded logo"
            />
            <i
              className={`${classes.logoRemove} material-icons`}
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  setPersonalizedQuestionsProgressAction({
                    logo: { ...progressState.logo, uploadedLogo: null }
                  })
                );
              }}
            >
              close
            </i>
          </React.Fragment>
        )}
      </div>
      {requiredValidationFailed && (
        <p className={classes.validationText}>Logo is required</p>
      )}
    </div>
  );
}
