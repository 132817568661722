import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";

export const handleSelectedLogoOption = (
  selectedId,
  question,
  code,
  options,
  dispatch,
  progressState,
  setState,
  dependedFields
) => {
  console.log("logo33", code, dependedFields);
  // if (code !== "Logo-Cheque-C") return;

  if (!code.match(/logo-(?!.*\bupload\b)\w*-c(?:-\w+)?/i)) return;

  const foundLogoOptionsDependedField =
    dependedFields && dependedFields.find(d => d.dependedOn === "logoMode");

  if (foundLogoOptionsDependedField) {
    let quesDetailID =
      foundLogoOptionsDependedField.quesDetails &&
      foundLogoOptionsDependedField.quesDetails[0] &&
      foundLogoOptionsDependedField.quesDetails[0].detailID;

    setState({ [quesDetailID]: "" });
  }

  let selectedOption = options.find(opt => opt.detailID === selectedId);

  let detailDesc = selectedOption && selectedOption.detailDesc;

  let logoMode = determineTheLogoMode(detailDesc);
  console.info("logo upload mode", detailDesc, logoMode);
  dispatch(
    setPersonalizedQuestionsProgressAction({
      logo: { ...progressState.logo, logoMode }
    })
  );
};

export const determineTheLogoMode = detailDesc => {
  let logoMode = "";
  if (detailDesc === "No Logo") {
    logoMode = "";
  } else if (detailDesc === "I want to upload my logo") {
    logoMode = "uploadLogo";
  } else if (detailDesc === "Logo Clipart") {
    logoMode = "pickClipart";
  } else if (detailDesc === "You have my logo on file") {
    logoMode = "existingLogo";
  } else {
    logoMode = "";
  }

  return logoMode;
};
