import React, { useEffect, useState, useRef } from "react";
import mergeRefs from "../../../../../functions/multiRef";
import { LINK_DISTRIBUTION } from "../../../../../project-config";

import classes from "./Dropdown.module.css";

export default function Dropdown({
  state,
  setState,
  label,
  options,
  type,
  name,
  firstOptionIsCheckedByDefault,
  progressState,
  setProgressState,
  fontSelect,
  required,
  selectLabel,
  setValidations,
  failedValidations,
  buttonRef,
  lastInputRef,
  lastIndex
}) {
  const [expanded, setExpanded] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);

  useEffect(() => {
    if (options && options[0] && firstOptionIsCheckedByDefault) {
      console.info("options", options);
      let defaultOptionIndex = null;
      if (fontSelect) {
        defaultOptionIndex = 31;
        setProgressState({
          setSelectedFont: true,
          value: options[defaultOptionIndex || 0].label
        });
      }
      setState({ [name]: options[defaultOptionIndex || 0].id });
    }

    if (required) {
      setValidations({ [name]: "required" });
    }
  }, []);

  useEffect(() => {
    console.info("failedValidations", failedValidations);
    if (failedValidations && failedValidations.validationType === "required") {
      console.info("failedValidations33", failedValidations[name]);

      setValidationMessage("Required");
    } else {
      setValidationMessage(null);
    }
  }, [failedValidations]);

  const handleOnClick = (e, name, id, btnLabel) => {
    console.info("clicked", id, name);
    if (fontSelect) {
      setProgressState({
        inputs: { ...progressState.inputs, [name]: id },
        font: { ...progressState.font, selectedFont: btnLabel }
      });
    } else {
      setState({ [name]: id });
    }

    setExpanded(false);
  };

  const handleSelectClicked = () => {
    setExpanded(!expanded);
  };

  const handleSelectedLogoOption = () => {
    if (state && Object.keys(state).length > 0 && state[name]) {
      let selectedId = state[name];

      let selectedOption = options.find(
        option => option.detailID === selectedId
      );

      console.info("selected logo", state, selectedId, selectedOption, type);

      if (selectedOption && type === "image") {
        let selectedImage = selectedOption.detailImg;
        let selectedPreviewValue = selectedOption.previewVal;
        return (
          <img
            src={LINK_DISTRIBUTION + "/" + selectedImage}
            alt={selectedPreviewValue}
          ></img>
        );
      } else {
        return selectedOption.detailDesc;
      }
    } else if (selectLabel) {
      return selectLabel;
    }

    return null;
  };

  const selectRef = useRef(null);

  return (
    <div className={classes.container}>
      <div id={name} className={classes.wrapper}>
        {label && (
          <p>
            {label}
            {required && <span style={{ color: "red" }}>&nbsp;*</span>}:
          </p>
        )}
        <div
          onClick={handleSelectClicked}
          aria-expanded={expanded}
          className={`${classes.selectWrapper}${
            validationMessage ? " " + classes.inputValidationFailed : ""
          }`}
          tabIndex="0"
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.preventDefault();
              handleSelectClicked();
            } else if (e.keyCode === 40) {
              e.preventDefault();
              setExpanded(true);
            } else if (e.keyCode === 38) {
              e.preventDefault();
              setExpanded(false);
            } else if (
              e.keyCode === 9 &&
              !e.shiftKey &&
              !expanded &&
              lastIndex.len - 1 === lastIndex.ind
            ) {
              e.preventDefault();
              buttonRef.current.focus();
            }
          }}
          ref={
            progressState.progress === 2 && lastIndex.len - 1 === lastIndex.ind
              ? mergeRefs(selectRef, lastInputRef)
              : selectRef
          }
        >
          <div className={classes.select}>{handleSelectedLogoOption()}</div>
          <i className="material-icons">
            {expanded ? `expand_less` : "expand_more"}
          </i>
        </div>
        <div
          className={`${classes.dropdownWrapper}${
            expanded ? " " + classes.expanded : ""
          }`}
        >
          {options.map(btn => {
            return (
              <div
                tabIndex={expanded ? "0" : "1"}
                onKeyPress={e => {
                  e.preventDefault();
                  handleOnClick(e, name, btn.id, btn.label);
                  selectRef.current.focus();
                }}
                onClick={e => handleOnClick(e, name, btn.id, btn.label)}
                id={btn.id}
                name={name}
                key={btn.id}
                className={`${classes.optionWrapper}${
                  (state[name] && state[name]) === btn.id
                    ? " " + classes.active
                    : ""
                }${type ? " " + classes[type] : ""}`}
              >
                {type === "color" && (
                  <React.Fragment>
                    <img
                      src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                      alt={btn && btn.label}
                    ></img>
                  </React.Fragment>
                )}
                {type === "image" && (
                  <img
                    src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                    alt={btn && btn.label}
                  ></img>
                )}
                {type !== "image" && (
                  <label htmlFor={btn.id}>{btn.label}</label>
                )}
              </div>
            );
          })}
        </div>
        {validationMessage && (
          <p className={classes.validationMessage}>{validationMessage}</p>
        )}
      </div>
    </div>
  );
}
