import React, { useLayoutEffect, useRef } from "react";
import { BASE_PATH, LINK_DISTRIBUTION } from "../../../../../project-config";

import classes from "./ReviewSection.module.css";
import TiffCanvas from "./TiffCanvas";

export default function ReviewSection({
  questions,
  inputs,
  progressState,
  firstInputRef,
  lastInputRef,
  buttonRef,
  setTempRef,
  tempRef
}) {
  const firstTempRef = useRef(null);
  const lastTempRef = useRef(null);

  const tiffLogo = progressState.logo.tiff;

  console.log("BASEPATH", BASE_PATH);

  useLayoutEffect(() => {
    if (progressState.progress === 3 && firstTempRef && firstTempRef.current) {
      setTempRef({ first: firstTempRef, last: lastTempRef });
      firstTempRef.current.focus();
    }
  }, [progressState]);
  return (
    <div className={classes.wrapper}>
      {questions.map((question, index) => {
        return (
          <div key={question.quesID} className={classes.inputContainer}>
            <h3 className={classes.title}>{question.quesDesc}</h3>
            {question.quesDetails.length === 0 &&
              inputs &&
              inputs[question.quesID] && <span>{inputs[question.quesID]}</span>}
            {question.quesDetails.map(ques => {
              if (question.quesCode === "logo-upload-Cheque-C") {
                return (
                  <div key={ques.detailID}>
                    {inputs[ques.detailID] &&
                      (tiffLogo ? (
                        <TiffCanvas tiffLogo={tiffLogo} classes={classes} />
                      ) : (
                        <img
                          className={classes.uploadedLogo}
                          src={`${BASE_PATH}${
                            inputs[ques.detailID] &&
                            inputs[ques.detailID][1] &&
                            inputs[ques.detailID][1]
                          }`}
                          alt="uploaded logo"
                        />
                      ))}
                  </div>
                );
              } else if (
                inputs &&
                inputs[question.quesID] &&
                inputs[question.quesID] === String(ques.detailID)
              ) {
                return (
                  <div key={ques.detailID} className={classes.inputWrapper}>
                    {ques &&
                    ques.detailImg &&
                    question.quesCode.toLowerCase().includes("logooptions") ===
                      false ? (
                      <img
                        src={LINK_DISTRIBUTION + "/" + ques.detailImg}
                        alt={ques.previewVal}
                        ref={
                          index === 0
                            ? firstTempRef
                            : questions.length - 1 === index
                            ? lastTempRef
                            : null
                        }
                        onKeyDown={e => {
                          if (
                            questions.length - 1 === index &&
                            !e.shiftKey &&
                            e.keyCode === 9
                          ) {
                            e.preventDefault();
                            buttonRef.current.focus();
                          }
                        }}
                        tabIndex="0"
                      ></img>
                    ) : question.quesType.toLowerCase().includes("color") ? (
                      <span
                        ref={
                          index === 0
                            ? firstTempRef
                            : questions.length - 1 === index
                            ? lastTempRef
                            : null
                        }
                        onKeyDown={e => {
                          if (
                            questions.length - 1 === index &&
                            !e.shiftKey &&
                            e.keyCode === 9
                          ) {
                            e.preventDefault();
                            buttonRef.current.focus();
                          }
                        }}
                        tabIndex="0"
                        className={classes.colorBox}
                        style={{
                          backgroundColor: ques.previewVal.toLowerCase()
                        }}
                      ></span>
                    ) : (
                      <span
                        ref={
                          index === 0
                            ? firstTempRef
                            : questions.length - 1 === index
                            ? lastTempRef
                            : null
                        }
                        onKeyDown={e => {
                          if (
                            questions.length - 1 === index &&
                            !e.shiftKey &&
                            e.keyCode === 9
                          ) {
                            e.preventDefault();
                            buttonRef.current.focus();
                          }
                        }}
                        tabIndex="0"
                      >
                        {ques.detailDesc}
                      </span>
                    )}
                    {/*    {ques.detailDesc === "I want to upload my logo" &&
                      progressState.logo.uploadedLogo && (
                        <img
                          className={classes.uploadedLogo}
                          src={progressState.logo.uploadedLogo}
                          alt="uploaded logo"
                          ref={
                            index === 0
                              ? firstTempRef
                              : questions.length - 1 === index
                              ? lastTempRef
                              : null
                          }
                          onKeyDown={e => {
                            if (
                              questions.length - 1 === index &&
                              !e.shiftKey &&
                              e.keyCode === 9
                            ) {
                              e.preventDefault();
                              buttonRef.current.focus();
                            }
                          }}
                          tabIndex="0"
                        />
                      )} */}
                    {/*    {ques.detailDesc === "Logo Clipart" &&
                      progressState.logo.selectedClipart && (
                        <img
                          className={classes.uploadedLogo}
                          src={progressState.logo.selectedClipart.url}
                          alt="selected clipart"
                          ref={
                            index === 0
                              ? firstTempRef
                              : questions.length - 1 === index
                              ? lastTempRef
                              : null
                          }
                          onKeyDown={e => {
                            if (
                              questions.length - 1 === index &&
                              !e.shiftKey &&
                              e.keyCode === 9
                            ) {
                              e.preventDefault();
                              buttonRef.current.focus();
                            }
                          }}
                          tabIndex="0"
                        />
                      )} */}
                  </div>
                );
              } else if (
                (question.quesInput === "text" ||
                  question.quesInput === "checkbox") &&
                inputs &&
                inputs[ques.detailID] !== null
              ) {
                let input = inputs[ques.detailID];

                input = input === true ? "Yes" : input === false ? "No" : input;

                return (
                  <div key={ques.detailID} className={classes.inputWrapper}>
                    <label>{ques.detailDesc}</label>
                    <span
                      tabIndex="0"
                      onKeyDown={e => {
                        if (
                          questions.length - 1 === index &&
                          !e.shiftKey &&
                          e.keyCode === 9
                        ) {
                          e.preventDefault();
                          buttonRef.current.focus();
                        }
                      }}
                      ref={
                        index === 0
                          ? firstTempRef
                          : questions.length - 1 === index
                          ? lastTempRef
                          : null
                      }
                      className={classes.reviewInput}
                      dangerouslySetInnerHTML={{
                        __html: input === "" ? "" : input
                      }}
                    ></span>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      })}
    </div>
  );
}
