import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../assets/css/product-sl.css";
import "./Styles/OFBProductPage.css";

import ImageCarousel from "./Components/ImageCarousel/BDImageCarousel";
import AddToCartBox from "./Components/AddToCart/AddToCartBox";
import AddedToCartModal from "./Components/AddToCart/Components/AddedToCartModal";

import htmldecoder, { htmlDecode } from "../../functions/htmldecoder";
import ShareButtons from "./Components/ShareButtons/ShareButtons";
import ItemCode from "./Components/ItemCode/ItemCode";
import OtherInfoTab from "./Components/OtherInfoTab/OtherInfoTab";

import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import { I18nContext } from "../../i18n/index";
import { navigate, useLocation } from "@reach/router";
import {
  fetchDirectUrlGetItem,
  getSupplierInfo,
  mapProductUrls
} from "../../redux/actions/productAction";
import ConfirmDeliveryOptions from "../AC-ProductPage/Components/AddToCart/Components/ConfirmDeliveryOptions/ConfirmDeliveryOptions";
import CartValidationErrors from "../AC-ProductPage/Components/CartValidationErrors/CartValidationErrors";
import OutOfStockError from "../AC-ProductPage/Components/CartValidationErrors/OutOfStockError";

import { setGoBackToCategoryFromProductPage } from "../../redux/actions/categoryActions";
import { LINK_DISTRIBUTION } from "../../project-config";
import { GATSBY_DEFAULT_LANGUAGE } from "../../../preBuildScripts/misc/links";

const OFBProductPage = ({ data, storeInfo, dynamic }) => {
  console.info("storeinfo", storeInfo);
  const { translate, langCode } = React.useContext(I18nContext);
  const location = useLocation();

  const dispatch = useDispatch();
  if (dynamic && !location.pathname.includes("/biid/")) {
    navigate("/");
  }

  const [titleState, setTitleState] = useState("");
  const [enquiryModalState, setEnquiryModalState] = useState(false);

  const [supplierName, setSupplierName] = React.useState("");
  const [supplierCity, setSupplierCity] = React.useState("");

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const ItemCodeState = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  const itemDetailIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const productInitialStateFromFetch = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const supplierInfoReducer = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );
  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  React.useEffect(() => {
    if (!data && supplierInfoReducer.length > 0) {
      if (supplierInfoReducer[0].distributorOrder.length > 0) {
        setSupplierName(supplierInfoReducer[0].distributorOrder[0].name);
        setSupplierCity(supplierInfoReducer[0].distributorOrder[0].city);
      }
    }
  }, [supplierInfoReducer]);

  useEffect(() => {
    console.info("RENDER++", itemDetailState.code);
    if (typeof window !== undefined && itemDetailIdState) {
      //  dispatch(getSupplierInfo(itemDetailIdState));
      window.scrollTo(0, 0);
    }
  }, [itemDetailIdState]);

  useEffect(() => {
    if (!data && productInitialStateFromFetch.title != "") {
      /*  if (productInitialState != "") {
        setTitleState(itemDetailsTitleState);
      } else { */
      setTitleState(productInitialStateFromFetch.title);
      /*       } */
    }
  }, [productInitialStateFromFetch /* , itemDetailsTitleState */]);
  const productUnavailableState = useSelector(
    state => state.productReducer.productUnavilable,
    shallowEqual
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "share-popover" : undefined;

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const isLoggedInState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const [swipeLoc, setSwipeLoc] = React.useState({ start: 0, end: 0 });

  const reviewsContainerRef = useRef();

  console.info("borop data", data, storeInfo);

  const [count, setCount] = useState(0);

  const [starFilter, setStarFilter] = useState([]);
  const [avgRating, setAvgRating] = useState({
    avgRating: 0,
    countOfEachStar: {},
    totalValue: 0
  });

  const [reviewsWithImages, setReviewsWithImages] = useState([]);

  useEffect(() => {
    if (typeof window !== undefined) window.scrollTo(0, 0);

    //dispatch(getSupplierInfo(data._0.id));
    dispatch(setGoBackToCategoryFromProductPage(true));

    return () => {
      dispatch(setGoBackToCategoryFromProductPage(false));
    };
  }, []);

  /* useLayoutEffect(() => {
    console.info("PRODINDEX 222", data._0.breadcrumbs);
    const { left, right } = mapProductUrls(
      location.pathname,
      data._0.breadcrumbs[data._0.breadcrumbs.length - 1].url.replace(
        "shop/",
        ""
      )
    );
    if (typeof window !== undefined) {
      let start = 0;
      let end = 0;
      let startY = 0;
      let endY = 0;
      let touchTime = 0;
      let diff = 0;

      const touchStartEvent = e => {
        console.error("HELLO 1");
        if (e.changedTouches[0]) {
          start = e.changedTouches[0].screenX;
          startY = e.changedTouches[0].screenY;
          touchTime = new Date();
        }
      };

      const touchEndEvent = e => {
        console.error("HELLO 2");
        if (e.changedTouches[0]) {
          end = e.changedTouches[0].screenX;
          endY = e.changedTouches[0].screenY;
          diff = new Date() - touchTime;
          handleSwiper();
        }
      };

      const handleSwiper = () => {
        if (Math.abs(end - start) > Math.abs(endY - startY) && diff > 100) {
          if (end < start) {
            navigate(`/${left}`, { state: { directionCat: "left" } });
          } else if (end > start) {
            navigate(`/${right}`, { state: { directionCat: "right" } });
          }
        }
      };

      window.addEventListener("touchstart", touchStartEvent);

      window.addEventListener("touchend", touchEndEvent);

      return () => {
        window.removeEventListener("touchstart", touchStartEvent);
        window.removeEventListener("touchend", touchEndEvent);
      };
    }
  }, []);
 */
  useEffect(() => {
    if (count == 0 && typeof localStorage !== undefined) {
      setCount(count + 1);
      let languageStorage = null; //localStorage.getItem("language");
      if (!languageStorage) {
        localStorage.setItem("language", langCode);
        languageStorage = GATSBY_DEFAULT_LANGUAGE;
      }
      let countryStorage = localStorage.getItem("country");
      if (!countryStorage) {
        countryStorage = GATSBY_DEFAULT_LANGUAGE;
        localStorage.setItem("country", countryStorage);
      }
      let url = location.pathname;
      if (url[url.length - 1] === "/") url = url.substr(0, url.length - 1);

      const callback = res => {
        console.info("callback product", res);
        if (res && res.message && res.message.includes("ERROR")) {
          alert(res.message);
          if (res.message.includes("404")) {
            navigate("/404");
          } else navigate("/");
        }
      };

      if (url.includes(`/${localStorage.getItem("language")}/`) === false) {
        dispatch(
          fetchDirectUrlGetItem(
            url,
            localStorage.getItem("language"),
            countryStorage,
            data ? data._0.id : null,
            callback
          )
        );
      } else {
        setCount(count + 1);
        dispatch(
          fetchDirectUrlGetItem(url, langCode, countryStorage, null, callback)
        );
      }
    }

    return () => {
      setCount(0);
    };
  }, [location.pathname, langCode]);

  var mapsLink;
  if (
    storeInfo &&
    storeInfo.storeSellerData &&
    Object.keys(storeInfo.storeSellerData).includes("location") &&
    storeInfo.storeSellerData.location
  ) {
    mapsLink =
      "https://www.google.ca/maps/search/" + storeInfo.storeSellerData.location;
  } else {
    mapsLink = "";
  }

  return (
    <React.Fragment>
      {/*  <EnquiryModal
        isLoggedIn={loginNameState !== ""}
        enquiryModalState={enquiryModalState}
        setEnquiryModalState={setEnquiryModalState}
      /> */}
      {/* <AccessoryModal /> */}

      <div id="bd" className="item">
        <Breadcrumb back={true} />
        <div className="col s12">
          <div style={{ marginTop: "5px" }}>
            <div className="item-main">
              <div id="mainGridDiv" className="item-top-row row">
                {isMobileState ? (
                  <React.Fragment>
                    <p className="brandItem">
                      <span id="buyBoxDistName">
                        {data &&
                        data.supplierData &&
                        data.supplierData._xResult &&
                        data.supplierData._xResult.length > 0 &&
                        data.supplierData._xResult[0].distributorOrder &&
                        data.supplierData._xResult[0].distributorOrder.length >
                          0 &&
                        data.supplierData._xResult[0].distributorOrder[0].name
                          ? data.supplierData._xResult[0].distributorOrder[0]
                              .name
                          : ""}
                      </span>
                    </p>

                    <h1
                      className="regularTitle title itemTitle itemTitleMobile"
                      dangerouslySetInnerHTML={{
                        __html: htmldecoder(
                          data
                            ? data.productData._xResult[0].title
                            : itemDetailState && itemDetailState.title
                        )
                      }}
                    ></h1>
                  </React.Fragment>
                ) : null}

                <div id="leftGrid">
                  <div id="titleGrid">
                    {!isMobileState ? (
                      <React.Fragment>
                        <h1
                          style={{ marginBottom: "10px" }}
                          className="regularTitle title itemTitle itemTitleMobile"
                          dangerouslySetInnerHTML={{
                            __html: htmldecoder(
                              data
                                ? data.productData._xResult[0].title
                                : itemDetailState && itemDetailState.title
                            )
                          }}
                        ></h1>
                        {/*    <ReviewBar
                          avgRating={avgRating}
                          starFilter={starFilter}
                          setStarFilter={setStarFilter}
                          reviewsContainerRef={reviewsContainerRef}
                        /> */}
                      </React.Fragment>
                    ) : null}
                    {/*     {!isMobileState && isLoggedInState ? (
                      <React.Fragment>
                        <WishListBar
                          data={data}
                          productUnavailable={productUnavailableState}
                          price={data.productData._xResult[0].prices[0].price_1}
                        />
                      </React.Fragment>
                    ) : null} */}
                    <ItemCode
                      code={
                        data ? data.productData._xResult[0].code : ItemCodeState
                      }
                    />
                    <div
                      style={{ display: "none" }}
                      className="col-xs-12 product-page-seller-info"
                    >
                      {!isMobileState ? (
                        <React.Fragment>
                          {/* <div className="inStock-container">
                            {priceState && priceState.invs && priceState.invs[0] ? (
                              <div className="inStock-wrapper">
                                <label>{translate("js.item.stock")}:</label>
                                &nbsp;
                                <span style={{ fontWeight: "400" }}>
                                  {priceState.invs[0].instock}
                                </span>
                              </div>
                            ) : null}
                          </div> */}

                          <p>
                            <span>{translate("js.item.available")}: </span>
                            <span
                              id="buyBoxDistName"
                              style={{ fontWeight: "400" }}
                            >
                              {data &&
                              data.supplierData &&
                              data.supplierData._xResult &&
                              data.supplierData._xResult.length > 0 &&
                              data.supplierData._xResult[0].distributorOrder &&
                              data.supplierData._xResult[0].distributorOrder
                                .length > 0 &&
                              data.supplierData._xResult[0].distributorOrder[0]
                                .name
                                ? data.supplierData._xResult[0]
                                    .distributorOrder[0].name
                                : ""}
                            </span>
                          </p>
                        </React.Fragment>
                      ) : null}
                      <p>
                        <strong>{translate("js.item.location")}: </strong>
                        <span id="buyBoxDistLoc" style={{ fontWeight: "400" }}>
                          {data &&
                          data.supplierData &&
                          data.supplierData._xResult &&
                          data.supplierData._xResult.length > 0 &&
                          data.supplierData._xResult[0].distributorOrder &&
                          data.supplierData._xResult[0].distributorOrder
                            .length > 0 &&
                          data.supplierData._xResult[0].distributorOrder[0].city
                            ? data.supplierData._xResult[0].distributorOrder[0]
                                .city
                            : ""}
                        </span>
                      </p>

                      <div className="product-page-seller-rating">
                        <strong>
                          {translate("js.item.sellerrating")}:&nbsp;
                        </strong>
                        <div id="buyBoxDistRate">
                          <div className="dist-item-rating">
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <i className="material-icons star-icon">
                              star_border
                            </i>
                            <div
                              style={{ display: "none" }}
                              className="distReviewCount"
                            >
                              0 {translate("js.item.reviews")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ImageCarousel
                    productUnavailable={productUnavailableState}
                    giftcard="false"
                    data={{
                      productCode: data
                        ? data.productData._xResult[0].code
                        : itemDetailState.code,
                      itemId: data ? data._0.id : itemDetailState.itemId,
                      hiddenProperties: data
                        ? data.productData._xResult[0].hiddenProperties
                        : itemDetailState.hiddenProperties,
                      title: data
                        ? data.productData._xResult[0].title
                        : itemDetailState.title,
                      url: data ? data.url : ""
                    }}
                    title={
                      data
                        ? data.productData._xResult[0].title
                        : itemDetailState && itemDetailState.title
                    }
                    /*      imageUrl={data.productData._xResult[0].image.replace(
                      "images",
                      "largeimages"
                    )} */
                  />
                  {!isMobileState && (
                    <ShareButtons
                      title={
                        data
                          ? data.productData._xResult[0].title
                          : itemDetailState.title && itemDetailState.title
                      }
                      imageUrl={`${LINK_DISTRIBUTION}/${
                        itemDetailState && itemDetailState.image
                      }`}
                    />
                  )}
                </div>
                <div id="rightGrid">
                  {
                    /* isLoggedInState */ true ? (
                      <AddToCartBox
                        data={data}
                        productUnavailable={productUnavailableState}
                        giftcard="false"
                        prices={
                          data
                            ? data.productData._xResult[0].prices
                            : itemDetailState && itemDetailState.prices
                        }
                        title={htmlDecode(
                          data
                            ? data.productData._xResult[0].title
                            : itemDetailState && itemDetailState.title
                        )}
                      />
                    ) : (
                      <div className="cart-box-login-prompt">
                        Login to See Pricing
                      </div>
                    )
                  }
                </div>
                <ConfirmDeliveryOptions />
                <CartValidationErrors />
                <OutOfStockError />
                <AddedToCartModal />
              </div>
            </div>
          </div>
        </div>
        {/* <Accessories /> */}
        {/* <VendorList
          handleEnquiryModalOpenClicked={handleEnquiryModalOpenClicked}
        /> */}
        <OtherInfoTab
          longDesc={{
            longdesc: data
              ? data.productData._xResult[0].longdesc
              : itemDetailState && itemDetailState.longdesc,
            longdesc2: data
              ? data.productData._xResult[0].longdesc2
              : itemDetailState && itemDetailState.longdesc2,
            longdesc3: data
              ? data.productData._xResult[0].longdesc3
              : itemDetailState && itemDetailState.longdesc3,
            longdesc4: data
              ? data.productData._xResult[0].longdesc4
              : itemDetailState && itemDetailState.longdesc4
          }}
          properties={
            data
              ? data.productData._xResult[0].properties
              : itemDetailState && itemDetailState.properties
          }
        />

        <div className="clearfix"></div>
        {/*storeInfo.storeSellerData && (
          <div className="storeInfoWrapper itemStoreInfo">
            <div className="storeLogo">{<img src={imageUrl}></img>}</div>
            <div className="storeInfoMain">
              <div className="storeInfo1">
                <h1 className="storeName testClass">
                  {storeInfo.storeSellerData && storeInfo.storeSellerData.brand}
                </h1>
                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("year") &&
                storeInfo.storeSellerData.year != "" ? (
                  <p>
                    <span>
                      Established{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "year"
                        ) &&
                        storeInfo.storeSellerData.year}{" "}
                      -{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "city"
                        ) &&
                        storeInfo.storeSellerData.city}
                      ,{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "province"
                        ) &&
                        storeInfo.storeSellerData.province}
                    </span>
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes(
                  "phone_number"
                ) &&
                storeInfo.storeSellerData.phone_number != "" ? (
                  <p>
                    <span>Phone:</span>{" "}
                    {storeInfo.storeSellerData &&
                      Object.keys(storeInfo.storeSellerData).includes(
                        "phone_number"
                      ) &&
                      storeInfo.storeSellerData.phone_number}
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("location") &&
                storeInfo.storeSellerData.location != "" ? (
                  <p>
                    <span>Address:</span>{" "}
                    {storeInfo.storeSellerData &&
                      Object.keys(storeInfo.storeSellerData).includes(
                        "location"
                      ) &&
                      storeInfo.storeSellerData.location}
                    <br />
                    {mapsLink != "" ? (
                      <a className="mapsLink" target="_blank" href={mapsLink}>
                        View on Google Maps
                      </a>
                    ) : null}
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("website") &&
                storeInfo.storeSellerData.website != null ? (
                  <p>
                    <span>Website:</span>{" "}
                    {storeInfo.storeSellerData.website}
                  </p>
                ) : null}
                <p className="sellerSocialLine">
                  {storeInfo.storeSellerData !== undefined &&
                  (Object.keys(storeInfo.storeSellerData).includes(
                    "facebook"
                  ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "twitter"
                    ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "instagram"
                    ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "pinterest"
                    )) &&
                  (storeInfo.storeSellerData.facebook != null ||
                    storeInfo.storeSellerData.twitter != null ||
                    storeInfo.storeSellerData.instagram != null ||
                    storeInfo.storeSellerData.pinterest != null) ? (
                    <span>
                      <span>Connect With Us:</span>
                      <br />
                    </span>
                  ) : null}

                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes("facebook") &&
                  storeInfo.storeSellerData.facebook != null ? (
                    <a href={storeInfo.storeSellerData.facebook}>
                      <img className="sellerSocialLogo" src={fbLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes("twitter") &&
                  storeInfo.storeSellerData.twitter != null ? (
                    <a href={storeInfo.storeSellerData.twitter}>
                      <img className="sellerSocialLogo" src={twLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes(
                    "instagram"
                  ) &&
                  storeInfo.storeSellerData.instagram != null ? (
                    <a href={storeInfo.storeSellerData.instagram}>
                      <img className="sellerSocialLogo" src={igLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes(
                    "pinterest"
                  ) &&
                  storeInfo.storeSellerData.pinterest != null ? (
                    <a href={storeInfo.storeSellerData.pinterest}>
                      <img className="sellerSocialLogo" src={ptLogo} />
                    </a>
                  ) : null}
                </p>
              </div>
              <div className="storeInfo2">
                <h2 className="storeName">About Us</h2>

                <div id="aboutInfo">
                  <p>
                    {storeInfo.storeSellerData &&
                      storeInfo.storeSellerData.description &&
                      storeInfo.storeSellerData.description.blocks &&
                      storeInfo.storeSellerData.description.blocks[0] &&
                      storeInfo.storeSellerData.description.blocks[0].text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )*/}
      </div>
    </React.Fragment>
  );
};

export default OFBProductPage;
