import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";

export const handleSelectedSignatureLineQuestion = (
  selectedId,
  question,
  options,
  dispatch,
  progressState
) => {
  console.info("selected 2ndSignature", question);
  if (question.toLowerCase().includes("2ndsignature") === false) return;

  let selectedOption = options.find(opt => opt.detailID === selectedId);

  if (!selectedOption) {
    dispatch(
      setPersonalizedQuestionsProgressAction({
        signatureLine: {}
      })
    );
  } else {
    let detailDesc = selectedOption && selectedOption.detailDesc;

    console.info("selected ink progress", progressState);
    if (detailDesc.toLowerCase().includes("yes"))
      dispatch(
        setPersonalizedQuestionsProgressAction({
          signatureLine: {
            selectedOption //true if it includes oui, render the 2nd line
          }
        })
      );
    else {
      dispatch(
        setPersonalizedQuestionsProgressAction({
          signatureLine: {}
        })
      );
    }
  }
};
