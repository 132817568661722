import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import htmldecoder from "../../../../functions/htmldecoder";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/OtherInfoTab.css";

const OtherInfoTab = ({ longDesc, properties }) => {
  const { translate } = React.useContext(I18nContext);
  console.info("properties", properties);
  return (
    <div
      className="product-details-specs-container"
      style={{ backgroundColor: "white" }}
    >
      <div className="product-details-specs-wrapper">
        <div className="product-details-wrapper">
          <h2>{translate("js.item.productdetails")}</h2>
          <ul id="description" className="otherDesktop">
            {Object.keys(longDesc).map((desc, index) => {
              if (longDesc[desc] !== "") {
                return (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: htmldecoder(longDesc[desc])
                    }}
                  />
                );
              }
            })}

            {properties && properties.length > 0
              ? properties
                ? properties.map(property => {
                    let key = property.propname;
                    let value = property.propvalue;
                    if (
                      !key.includes("_") &&
                      !["UPC", "Stock", "Quantity"].includes(key) &&
                      value
                    )
                      return (
                        <li className="specs-list-item" key={key}>
                          <label>
                            <strong>{key}:&nbsp;</strong>
                          </label>
                          <span>{value}</span>
                        </li>
                      );
                  })
                : null
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OtherInfoTab;
