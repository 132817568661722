const encodeConverter = (encodedString = "") => {
  console.info("encodedString", encodedString);
  const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">"
  };
  return encodedString
    .replace(translate_re, function (match, entity) {
      return translate[entity];
    })
    .replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    });
};

export const htmlDecode = content => {
  return content;
};

export const htmlDecodePreviewSection = content => {
  let e = document.createElement("div");

  e.innerHTML = content;
  let value = e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;

  return value;
};

export default encodeConverter;
