import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LazyLoad from "react-lazyload";
import "./Styles/ImageCarousel.css";
import "./Styles/FontStyles.css";

import {
  setPersonalizedQuestionsProgressAction,
  setProductImagecarouselInitialAction
} from "../../../../redux/actions/productAction";
import { maskChars, usePrevious } from "../../../../functions/Utilities";

import LazyloadImage from "../../../OFB-Category/components/ItemCard/components/LazyLoadImage";

import ShareButtons from "../../Components/ShareButtons/ShareButtons";
import ShareIcon from "@material-ui/icons/Share";

import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import {
  PROJECT_LINK,
  VID,
  LINK_DISTRIBUTION
} from "../../../../project-config";
import { htmlDecodePreviewSection } from "../../../../functions/htmldecoder";

let defaultX = 0;
let defaultScale = 1;

const areEqual = (prevProps, nextProps) => {
  console.info("borop rerender", nextProps);
  return nextProps.productImageSwitch == false;
};

const ImageCarousel = React.memo(function ImageCarousel(props) {
  const dispatch = useDispatch();
  const [mainImage, setMainImage] = useState("");

  const [mainImageUrl, setMainImageUrl] = useState("");

  const [wholeImageUrl, setWholeImageUrl] = useState("");
  const [galleryImagesCount, setGalleryImagesCount] = useState([]);
  const [sysNumImages, setSysNumImages] = useState(0);

  const [image404, setImage404] = useState(null);

  const [customizerImageState, setCustomizerImageState] = useState({
    initialImage: null,
    alternativeImage: null
  });

  const transformRef = useRef(null);
  const previewRef = useRef(null);
  const [imageLocation, setimageLocation] = useState(null);

  const [logo, setLogo] = useState(null);
  const productImageSwitchState = props.productImageSwitch;

  const productPageImageCarouselInitialState = useSelector(
    state => state.productReducer.productPageImageCarouselInitial,
    shallowEqual
  );
  console.info(
    "farop state",
    props,
    mainImageUrl,
    productImageSwitchState,
    productPageImageCarouselInitialState,
    wholeImageUrl
  );

  const productInitialState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );

  const productCode = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );
  const image3 = useSelector(
    state => state.productReducer.itemDetail.image3,
    shallowEqual
  );

  const image3ext = image3?.split(".")?.[1];

  const loadingState = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const itemTitleState = useSelector(
    state => state.productReducer.itemDetail.title,
    shallowEqual
  );

  const previousItemIdState = usePrevious(
    Object.assign({}, { itemIdState, itemTitleState })
  );

  const propertiesState = useSelector(
    state => state.productReducer.itemDetail.properties,
    shallowEqual
  );

  const personalizedQuestionsState = useSelector(
    state => state.productReducer.personalizedQuestions,
    shallowEqual
  );

  const trancodeState = useMemo(() => {
    if (
      personalizedQuestionsState &&
      personalizedQuestionsState.dependedFields
    ) {
      const question = personalizedQuestionsState.dependedFields.find(q =>
        q.quesCode.toLowerCase().includes("trancode")
      );
      return question;
    } else return null;
  }, [personalizedQuestionsState]);

  const companyInformationQuestionState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.detailIDs
        ?.companyInformationQuestion,
    shallowEqual
  );

  const companyInformationQuestionDetailIdsState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.detailIDs
        ?.companyInformationQuestionDetailIds,
    shallowEqual
  );

  const productTypeState = useMemo(() => {
    if (productInitialState && productInitialState.hiddenProperties) {
      const productType = productInitialState.hiddenProperties.find(
        prop => Object.keys(prop)[0].toLocaleLowerCase() === "product_type"
      );

      if (productType) {
        return Object.values(productType)[0];
      }
    }
    return "";
  }, [productInitialState]);

  const curCodeUsdAndChequeProductState = useMemo(() => {
    return (
      personalizedQuestionsProgressState &&
      personalizedQuestionsProgressState?.curCodeUsdAndChequeProduct
    );
  }, [personalizedQuestionsProgressState?.curCodeUsdAndChequeProduct]);

  const maskedInputIds = personalizedQuestionsState.maskedInputIds;
  const personalizedQuestionsProgressDisplayQuestionsState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.displayQuestions,
    shallowEqual
  );

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const mapColors = colors => {
    return colors.map(color => {
      color = color.toLowerCase();
      if (color === "navy blue") color = "navy";
      else if (color === "burgundy") color = "#800020";
      else if (color === "hunter green") color = "#355e3b";
      else if (color === "reflex blue") color = "#171796";
      return color;
    });
  };
  console.info("borop rerender2", productImageSwitchState);

  useEffect(() => {
    console.info("farop prev", previousItemIdState, itemIdState);
    if (
      previousItemIdState &&
      previousItemIdState.itemIdState != undefined &&
      previousItemIdState.itemIdState != itemIdState
    ) {
      if (previousItemIdState.itemTitleState == itemTitleState)
        dispatch(setProductImagecarouselInitialAction(false));
    }
  }, [previousItemIdState]);

  useEffect(() => {
    if (
      (productImageSwitchState || productPageImageCarouselInitialState) &&
      image3
    ) {
      console.info("borop rerender3", productImageSwitchState);
      if (image3.includes(productCode) === false) {
        setMainImage(`${LINK_DISTRIBUTION}/${image3}`);
      } else setMainImage(productCode);
    }
  }, [productCode, image3]);

  useEffect(() => {
    if (
      (productImageSwitchState || productPageImageCarouselInitialState) &&
      personalizedQuestionsProgressDisplayQuestionsState === false
    ) {
      setMainImageUrl(`${LINK_DISTRIBUTION}/store/${VID}/items/largeimages/`);
    } else if (personalizedQuestionsProgressDisplayQuestionsState) {
      setMainImageUrl(
        `${LINK_DISTRIBUTION}/store/${VID}/items/largeimages/` //backgrounds
      );
    }
  }, [itemIdState, personalizedQuestionsProgressDisplayQuestionsState]);
  console.log("mainImageUrl33", mainImageUrl);
  useEffect(() => {
    if (
      mainImage &&
      mainImageUrl
      /*   (mainImage && productImageSwitchState) ||
      (productPageImageCarouselInitialState && propertiesState) */
    ) {
      if (personalizedQuestionsProgressState.background.active) {
        const selectedColor =
          personalizedQuestionsProgressState.background.selectedColor;
        setWholeImageUrl(
          `${
            mainImageUrl.includes("/assets")
              ? mainImageUrl
              : mainImageUrl.replace("/items", "/assets/items")
          }${mainImage}-2-${selectedColor}.${image3ext}`
        );

        setCustomizerImageState({
          initialImage: `${mainImageUrl}${mainImage}.${image3ext}`
        });
      } else if (personalizedQuestionsProgressDisplayQuestionsState) {
        setWholeImageUrl(
          `${
            mainImageUrl.includes("/assets")
              ? mainImageUrl
              : mainImageUrl.replace("/items", "/assets/items")
          }${mainImage}-2.jpg`
        );
      } else setWholeImageUrl(`${mainImageUrl}${mainImage}.${image3ext}`);
    }
  }, [mainImage, mainImageUrl, personalizedQuestionsProgressDisplayQuestionsState, personalizedQuestionsProgressState.background]);

  useEffect(() => {
    console.info("borop image", productImageSwitchState, propertiesState);
    if (
      (propertiesState && productImageSwitchState) ||
      (productPageImageCarouselInitialState && propertiesState)
    ) {
      let tempNumber = propertiesState.filter(prop => {
        if (prop.propname === "Sys_Gallery_Images") {
          return true;
        } else {
          return false;
        }
      });

      tempNumber = tempNumber && tempNumber[0] && tempNumber[0].propvalue;
      let tempArray = [];

      for (let i = 1; i < Number(tempNumber); i++) {
        tempArray.push(i);
      }
      console.info("tempArray", tempArray);

      setGalleryImagesCount(tempArray);
    }
  }, [propertiesState]);

  useEffect(() => {
    console.info("borop image2", productImageSwitchState);

    if (
      (hiddenPropertiesState && productImageSwitchState) ||
      (productPageImageCarouselInitialState && hiddenPropertiesState)
    ) {
      let prop = hiddenPropertiesState.find(prop => {
        return prop.propname == "Sys_Num_Images";
      });
      if (prop === undefined) {
        setSysNumImages(0);
      } else if (parseInt(prop.propvalue) >= 1) {
        // setSysNumImages(parseInt(prop.propvalue));
      }
    }
  }, [hiddenPropertiesState]);

  const renderTheInputsOnTheImage = () => {
    if (
      personalizedQuestionsProgressState.background.imageExists !== false &&
      personalizedQuestionsProgressState &&
      personalizedQuestionsProgressState.inputs &&
      Object.keys(personalizedQuestionsProgressState.inputs).length > 0 &&
      personalizedQuestionsState &&
      Object.keys(personalizedQuestionsState).length > 0
    ) {
      let textFieldsIdsAndCoords =
        personalizedQuestionsState &&
        personalizedQuestionsState.textFields.reduce((a, question) => {
          let isCompanyInformationQuestion = null;
          let companyInformationFieldsArray = [];
          if (companyInformationQuestionState) {
            isCompanyInformationQuestion =
              question.quesID === companyInformationQuestionState.quesID;
          }

          let fontStyleMICRDigits =
            question.quesType === "startNumber" &&
            !question.quesCode.toLowerCase().includes("nbringdetails");
          question.quesDetails.forEach((detail, index) => {
            if (detail.previewVal.toLowerCase().includes("nonMICRFont")) {
              fontStyleMICRDigits = false;
            }

            let leftSymbol = "",
              rightSymbol = "";
            let appendLeft = "";
            let ignoreRendering = false;

            if (detail.defWords === "comet_bankBranchCd") {
              ignoreRendering = !personalizedQuestionsProgressState.separateMICRLines.includes(
                detail.detailID
              );
            } else if (detail.defWords === "comet_bankCd") {
              ignoreRendering = !personalizedQuestionsProgressState.separateMICRLines.includes(
                detail.detailID
              );
            } else if (detail.defWords === "comet_bankAcctCd") {
              const separateLines = personalizedQuestionsProgressState.separateMICRLines.includes(
                detail.detailID
              );
              // start number
              if (
                separateLines === false &&
                personalizedQuestionsProgressState.detailIDs &&
                personalizedQuestionsProgressState.detailIDs
                  .startNumberDetailId /* &&
                personalizedQuestionsProgressState.detailIDs
                  .startNumberPrefixDetailId */
              ) {
                const startNumber =
                  personalizedQuestionsProgressState.inputs[
                    personalizedQuestionsProgressState.detailIDs
                      .startNumberDetailId
                  ];
                /*    const startNumberPrefix =
                  personalizedQuestionsProgressState.inputs[
                    personalizedQuestionsProgressState.detailIDs
                      .startNumberPrefixDetailId
                  ]; */
                appendLeft += /*   startNumberPrefix || */ startNumber
                  ? `${fontStyleMICRDigits ? `&#9288;` : ""}${
                      /*   startNumberPrefix
                          ? startNumberPrefix + "-" + startNumber
                          : */ startNumber
                    }${fontStyleMICRDigits ? `&#9288;` : "-"}`
                  : "";
              }
              //transit number
              if (
                separateLines === false &&
                personalizedQuestionsProgressState.detailIDs &&
                personalizedQuestionsProgressState.detailIDs
                  .transitNumberDetailId
              ) {
                const transitNumber =
                  personalizedQuestionsProgressState.inputs[
                    personalizedQuestionsProgressState.detailIDs
                      .transitNumberDetailId
                  ];

                appendLeft += transitNumber
                  ? `${
                      fontStyleMICRDigits ? "&nbsp;&#9286;" : ""
                    }${transitNumber}${fontStyleMICRDigits ? "&nbsp;" : "-"}`
                  : "";
              }

              // instituion number
              if (
                separateLines === false &&
                personalizedQuestionsProgressState.detailIDs &&
                personalizedQuestionsProgressState.detailIDs
                  .institutionNumberDetailId
              ) {
                const instituionNuber =
                  personalizedQuestionsProgressState.inputs[
                    personalizedQuestionsProgressState.detailIDs
                      .institutionNumberDetailId
                  ];
                appendLeft += instituionNuber
                  ? `${fontStyleMICRDigits ? "&#9289;" : ""}${instituionNuber}${
                      fontStyleMICRDigits ? "&#9286;" : "&nbsp;&nbsp;&nbsp;"
                    }`
                  : "";
              }

              // account number

              let trancodeFirstDetailValue =
                trancodeState &&
                trancodeState.quesDetails &&
                trancodeState.quesDetails[0] &&
                trancodeState.quesDetails[0].detailLabelText;

              leftSymbol = fontStyleMICRDigits ? "&nbsp;" : "";
              rightSymbol = fontStyleMICRDigits
                ? `&#9288;${
                    trancodeFirstDetailValue && curCodeUsdAndChequeProductState
                      ? trancodeFirstDetailValue
                      : ""
                  }`
                : "";
            }

            let vertical = false;
            if (detail.previewVal.includes("vertical")) vertical = true;

            if (isCompanyInformationQuestion) {
              companyInformationFieldsArray.push({
                vertical,
                ignoreRendering,
                appendLeft,
                defWords: detail.defWords,
                applyFontStyle: detail.defWords === "Comet_ML",
                fontStyleMICRDigits,
                id: detail.detailID,
                x: detail.previewX,
                y: detail.previewY,
                h: detail.previewH,
                w: detail.previewW,
                leftSymbol,
                rightSymbol,
                isCompanyInformationQuestion
              });

              // if company name question fields are present add them to the array that is rendered
              if (index === question.quesDetails.length - 1) {
                a.push(companyInformationFieldsArray);
              }
            } else {
              a.push({
                vertical,
                ignoreRendering,
                appendLeft,
                defWords: detail.defWords,
                applyFontStyle: detail.defWords === "Comet_ML",
                fontStyleMICRDigits,
                id: detail.detailID,
                x: detail.previewX,
                y: detail.previewY,
                h: detail.previewH,
                w: detail.previewW,
                leftSymbol,
                rightSymbol
              });
            }
          });

          return a;
        }, []);

      let otherTextFieldsInsideRestOfTheFields = personalizedQuestionsState.restOfTheFields.filter(
        question => question.quesInput === "text"
      );

      let otherTextFields = otherTextFieldsInsideRestOfTheFields.reduce(
        (a, question) => {
          question.quesDetails.forEach(detail => {
            a.push({
              applyFontStyle: detail.defWords === "Comet_ML",
              id: detail.detailID,
              x: detail.previewX,
              y: detail.previewY,
              h: detail.previewH,
              w: detail.previewW
            });
          });

          return a;
        },
        []
      );

      if (otherTextFields) textFieldsIdsAndCoords.push(...otherTextFields);

      let logoQuestion = personalizedQuestionsState.restOfTheFields.find(
        // question => question.quesCode === "Logo-Cheque-C"

        question =>
          question.quesCode.match(/logo-(?!.*\bupload\b)\w*-c(?:-\w+)?/i)
      );

      let logoToRender = null;

      let logoMode = personalizedQuestionsProgressState.logo.logoMode;
      if (logoMode) {
        let quesDetailID =
          personalizedQuestionsProgressState.inputs[logoQuestion.quesID];

        let quesDetail = logoQuestion.quesDetails.find(
          detail => detail.detailID === quesDetailID
        );

        let { previewX: x, previewY: y, previewH: h, previewW: w } = quesDetail;

        if (logoMode === "pickClipart")
          logoToRender =
            personalizedQuestionsProgressState.logo.selectedClipart &&
            personalizedQuestionsProgressState.logo.selectedClipart.url;
        else if (logoMode === "uploadLogo")
          logoToRender = personalizedQuestionsProgressState.logo.uploadedLogo;

        logoToRender = {
          alt: quesDetail.detailDesc,
          id: logoQuestion.quesID,
          logo: logoToRender,
          x,
          y,
          h,
          w
        };
      }

      if (logoToRender) textFieldsIdsAndCoords.push(logoToRender);

      let {
        selectedFont,
        "Imprint Colour": imprintColor,
        "2nd Ink Color": secondInkColor
      } = personalizedQuestionsProgressState.font;

      let colors = mapColors([imprintColor, secondInkColor]);

      let addSignatureLine =
        personalizedQuestionsProgressState.signatureLine?.selectedOption;
      console.info("addSignatureLine", addSignatureLine);

      if (addSignatureLine) {
        textFieldsIdsAndCoords.push({
          signatureLine: true,
          id: addSignatureLine.detailID,
          x: addSignatureLine.previewX,
          y: addSignatureLine.previewY,
          h: addSignatureLine.previewH,
          w: addSignatureLine.previewW
        });
      }

      console.info("textFieldsIdsAndCoords", textFieldsIdsAndCoords);

      return textFieldsIdsAndCoords.map(field => {
        if (field && field.logo) {
          // setLogo(field.logo);
          return (
            <div
              style={{
                // display: field.w === "0" || field.h === "0" ? "none" : "",
                top: `${field.y}px`,
                left: `${field.x}px`,
                width: `${Number(field.w) || "50"}px`,
                height: `${Number(field.h) || "50"}px`
              }}
              className="pq-user-input-over-image pq-logo-wrapper"
              data-id={field.id}
            >
              {/*   <canvas ref={canvasRef}></canvas> */}
              <img id={"pq-logo"} src={field.logo} alt={field.alt} />
            </div>
          );
        } else if (
          field &&
          field.ignoreRendering !== true &&
          Object.keys(field).includes("logo") === false
        ) {
          const renderInputValue = field => {
            // don't render certain question details
            /*       if (
              [
                personalizedQuestionsProgressState.detailIDs
                  .startNumberPrefixDetailId
              ].includes(field.id)
            )
              return null; */
            if (maskedInputIds.includes(field.id)) {
              const maskedText = maskChars(
                personalizedQuestionsProgressState.inputs[field.id],
                4
              );

              if (maskedText) {
                const handleAccountNumberSymbolDependedOnLength = maskedText => {
                  const length = maskedText.length;
                  if (
                    length >= 11 &&
                    productTypeState.toLowerCase() === "deposit slip"
                  ) {
                    return `${maskedText.substring(0, 4)}${
                      field.fontStyleMICRDigits ? `&#9289;` : "-"
                    }${maskedText.substring(4)}`;
                  } else return maskedText;
                };
                return `${field.appendLeft ? field.appendLeft : ""}${
                  field.leftSymbol
                }${handleAccountNumberSymbolDependedOnLength(maskedText)}${
                  field.rightSymbol
                }`;
              } else if (field.appendLeft) {
                return `${field.appendLeft ? field.appendLeft : ""}`;
              }
              return "";
            }
            return personalizedQuestionsProgressState.inputs[field.id] &&
              personalizedQuestionsProgressState.inputs[field.id] !==
                "Not provided:  Leave blank"
              ? `${field.appendLeft ? field.appendLeft : ""}${
                  field.leftSymbol ? field.leftSymbol : ""
                }${htmlDecodePreviewSection(
                  personalizedQuestionsProgressState.inputs[field.id]
                )}${field.rightSymbol ? field.rightSymbol : ""}`
              : "";
          };
          return (
            <div
              style={{
                borderBottom: field.signatureLine ? "1px solid #666" : "",
                display: field.w === "0" || field.h === "0" ? "none" : "",
                top: `${field.y}px`,
                left: `${field.x}px`,
                width: `${field.w}px`,
                height: `${field.h}px`,
                color: `${
                  field.applyFontStyle ? colors[0] : colors[1] || colors[0]
                }`
              }}
              className={`pq-user-input-over-image${
                // field.fontStyleMICRDigits
                field.defWords === "MICR"
                  ? " MICRDigits"
                  : selectedFont && field.applyFontStyle
                  ? " " + selectedFont
                  : ""
              }`}
              data-id={field.id}
              dangerouslySetInnerHTML={{
                __html:
                  personalizedQuestionsProgressState.inputs[field.id] &&
                  personalizedQuestionsProgressState.inputs[field.id] !==
                    "Not provided:  Leave blank"
                    ? personalizedQuestionsProgressState.inputs[field.id]
                    : ""
              }}
            ></div>
          );
        } else return null;
      });
    }
    return null;
  };

  const resetZoom = () => {
    const div = transformRef.current.contentRef.current;
    let scale = 1;
    let heightScale = 1;
    const previewDiv = previewRef.current;

    scale = previewDiv.offsetWidth / div.offsetWidth;
    heightScale = previewDiv.offsetHeight / div.offsetHeight;

    scale = scale < heightScale ? scale : heightScale;

    const wDifference = div.offsetWidth * scale - previewDiv.offsetWidth;
    const hDifference = div.offsetHeight - previewDiv.offsetHeight;

    let negative = false;
    if (wDifference < 0) {
      negative = true;
    } else {
      defaultScale = scale;
    }
    console.info(
      "wDifference",
      hDifference,
      wDifference,
      negative,
      heightScale
    );
    const widthDifference = Math.abs(
      div.offsetWidth * scale - previewDiv.offsetWidth
    );

    defaultX = !negative ? (widthDifference / 2) * -1 : widthDifference / 2;

    setimageLocation({ defaultX, scale });

    console.info(
      "transformRef",
      div,
      previewDiv,
      widthDifference,
      div.offsetWidth,
      previewDiv.offsetWidth
    );
    setTimeout(() => {
      div.style.transform = `translate(${defaultX}px, 0px) scale(${scale})`;
    }, 50);
  };

  const renderMagnifier = () => {
    if (wholeImageUrl) {
      let imageUrl = wholeImageUrl.replace("/images", "/largeimages");

      if (image3.includes(productCode) === false) {
        imageUrl = mainImage;
      }

      let urlToUse = image404
        ? `${LINK_DISTRIBUTION}/store/${VID}/items/largeimages/noimage.jpg`
        : imageUrl;

      if (
        personalizedQuestionsProgressState.background.imageExists === false &&
        image404 !== true
      ) {
        urlToUse = customizerImageState.initialImage;
      }

      return (
        <div className={`${isMobileState ? "mobile-" : ""}magnifier-container`}>
          <TransformWrapper defaultScale={1} positionX={0} positionY={0}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
              return (
                <React.Fragment>
                  <div className="zoom-tools">
                    <button onClick={zoomIn}>
                      <i className="material-icons">zoom_in</i>
                    </button>
                    <button onClick={zoomOut}>
                      <i className="material-icons">zoom_out</i>
                    </button>
                    <button
                      onClick={resetZoom}
                      ref={props.imageCaroRef}
                      onKeyDown={e => {
                        console.log("SHIFT KEY RESIM", props.sectionRef);
                        if (
                          !e.shiftKey &&
                          e.keyCode === 9 &&
                          props.sectionRef &&
                          props.sectionRef.current
                        ) {
                          e.preventDefault();
                          props.sectionRef.current.focus();
                        }
                      }}
                    >
                      <i className="material-icons">zoom_out_map</i>
                    </button>
                  </div>
                  <TransformComponent ref={transformRef}>
                    {renderTheInputsOnTheImage()}
                    <img
                      onLoad={() => {
                        setTimeout(() => {
                          resetZoom();
                        }, 100);
                      }}
                      onError={() => {
                        if (
                          image404 === null &&
                          personalizedQuestionsProgressDisplayQuestionsState ===
                            false
                        ) {
                          setImage404(true);
                        }

                        if (
                          personalizedQuestionsProgressDisplayQuestionsState &&
                          personalizedQuestionsProgressState.background
                            .imageExists === null
                        ) {
                          dispatch(
                            setPersonalizedQuestionsProgressAction({
                              background: {
                                ...personalizedQuestionsProgressState.background,
                                imageExists: false
                              }
                            })
                          );
                        }
                      }}
                      /*   onLoad={resetTransform} */
                      src={urlToUse}
                      alt={`${itemTitleState}`}
                    ></img>
                  </TransformComponent>
                </React.Fragment>
              );
            }}
          </TransformWrapper>
        </div>
      );
    }
  };

  console.info("imageCarousel", galleryImagesCount.length, " ", sysNumImages);
  console.info(
    "farop img check",
    loadingState,
    wholeImageUrl,
    itemIdState,
    personalizedQuestionsProgressDisplayQuestionsState
  );
  return (
    <div id="imageGrid">
      <div className="preview" ref={previewRef} id="item-img">
        {renderMagnifier()}
      </div>
    </div>
  );
}, areEqual);

export default ImageCarousel;
