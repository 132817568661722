import { VID } from "../../../../../project-config";
import { POST_PERSONALIZED_QUESTIONS } from "../../../../../redux/links";
import { setLoadingAfterPostQuestions } from "../../../../../redux/actions/productAction";

const postPersonalizedQuestion = (
  productId = null,
  inputs,
  dispatchCallBack,
  updatePersonalizedInfo = false,
  dispatch
) => {
  let form = new FormData();
  form.append("disablePageCount", "0");
  form.append("_targetaddItems", `basket.html?vid=${VID}`);
  form.append(
    "mode",
    updatePersonalizedInfo ? "updatePersonalizedInfo" : "addItems"
  );

  Object.keys(inputs).forEach(key => {
    const input = inputs[key];
    if (input !== null) {
      if (Array.isArray(input)) {
        input.forEach((v, i) =>
          form.append(`answer${i !== 0 ? i + 1 : ""}_${key}`, v)
        );
      } else form.append(`answer_${key}`, input);
    }
  });
  form.append("basketPage", "Submit");

  fetch(POST_PERSONALIZED_QUESTIONS(productId), {
    method: "POST",
    body: form
    //redirect: "manual"
    //mode: "cors",
    //credentials: "include"
  })
    .then(response => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(data => {
          dispatch(setLoadingAfterPostQuestions(true));

          console.info("response", data);
          if (data && data.redirectTo) {
            window.location.href = data.redirectTo;
          }
        });
      } else {
        return response;
      }
    })
    .then(res => {
      dispatchCallBack(res.status);
    })
    .catch(err => {
      console.error("ERROR: failed posting personalized questions", err);
    });
};

export default postPersonalizedQuestion;
