import React from "react";
import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";

import classes from "./BundleItemsMessage.module.css";
import Button from "./Button";

export default function BundleItemsMessage({
  dispatch,
  displayBundleItemsMessage,
  bundleItems
}) {
  const handleClose = () => {
    dispatch(
      setPersonalizedQuestionsProgressAction({
        displayBundleItemsMessage: false
      })
    );
  };

  console.info("cheque initial5", displayBundleItemsMessage);

  if (displayBundleItemsMessage)
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <i onClick={handleClose} className="material-icons">
            close
          </i>
          <h6>
            This product have bundle product(s) attached to it and you will be
            required to customize the other product(s) after reviewing the
            current one.
          </h6>
          <h4>Bundle product(s) attached</h4>
          <ol>
            {bundleItems.map(item => (
              <li key={item.id}>{item.title}</li>
            ))}
          </ol>
          <Button onClick={handleClose} title="Okay" primary={true}>
            Okay
          </Button>
        </div>
      </div>
    );
  else return null;
}
